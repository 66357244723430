function getServerBaseURL(): string {
    if (BUILD_AWS) {
        return 'https://backup.archipad-services.com/';
    }

    if (BUILD_LOCAL) {
        return 'http://127.0.0.1/api/archipad/www/';
    }

    if (BUILD_LOCAL_DOCKER) {
        return 'http://127.0.0.1:62010/';
    }

    if (BUILD_PROD) {
        return 'https://api.archipad-services.com/';
    }

    return 'https://stage-api.bigsool.io/';
}

function getOAuthClientConfiguration(): { authority: string, client_id: string, redirect_uri: string, post_logout_redirect_uri?: string } {
    if (BUILD_PROD) {
        return {
            authority: OAUTH_AUTHORITY ?? null, // No default value yet
            client_id: OAUTH_CLIENT ?? null, // No default value yet
            post_logout_redirect_uri: OAUTH_POST_LOGOUT_REDIRECT_URI ?? null, // No default value yet
            redirect_uri: HOST_SERVER,
        };
    }

    return {
        authority: OAUTH_AUTHORITY ?? 'https://auth.archipad.dev/',
        client_id: OAUTH_CLIENT ?? 'a32b399b-d6d5-447c-9705-ed0887e1c0c8',
        post_logout_redirect_uri: OAUTH_POST_LOGOUT_REDIRECT_URI ?? HOST_SERVER,
        redirect_uri: HOST_SERVER,
    };
}

function getBucket(): string {
    if (BUILD_LOCAL || BUILD_LOCAL_DOCKER) {
        return "dev-archipad-services-us";
    }

    return "stage-archipad-services-fr";
}

// NTH : Merge cloud-app/env with contractor-app/env
const config = {
    "@core/services/locale": {
        supportedLocales: ['en', 'fr', 'es', 'de', 'it'],
    },
    "@core/services/config": {
        configPath: 'config',
        appVersion: '1.0',
        clientName: 'cloud',
        locale: 'en',
        hasReportGenerationService: true,
    },
    "@archipad/backend/project/dependenciesManager": {
        platform: "cloud",
    },

    "core/components/ui": {
        pixelMutiplier: 10.0,    // workaround for pixel rounding
    },

    "@archipad/report/report-generation-service": {
        reportGeneratorEnv: REPORT_GENERATOR_ENV,
	},
    "@archipad/oauth-client": getOAuthClientConfiguration(),

    "featureFlags": {
        "plusAuthenticationServer": false,
    },
};

const stage = {
    /** @TOOL Google Analytics test account */
    // "GTAG_ID": "G-X44CRH8N6C",

    "sentry": {
        environment: "stage",
        release: `archipad-cloud@${BUILD_REVISION}`,
    },

    "@archipad/backend/rpc/bootstrap": {
        serverBaseUrl: getServerBaseURL(),
        optimizeCorsPreflight: false,
        contractorPath: 'https://stage-contractor.bigsool.io/',
    },
    "@ui-archipad/backend/file-upload": {
        apiKey: "A5PFJScGyQb1CEPayM00pz",
        bucket: getBucket(),
    },
    "@ui-archipad/backend/archiweb/product": {
        apiKey: "pk_test_DQiAlW3v2J2Jb65foqjJCWRi",
    },
    "@core/services/logger.service": {
        DEBUG: true, INFO: true, WARN: true, ERROR: true,

        // @TOOL : Avoid having too much messages as Chrome dev tools becomes really buggy after
        // classes: {
        //     orm: { DEBUG: false, INFO: false, WARN: false, ERROR: true, },
        //     queue: { DEBUG: false, INFO: true, WARN: true, ERROR: true, },
        //     tracking:  { DEBUG: false, INFO: true, WARN: true, ERROR: true, },
        //     index:  { DEBUG: false, INFO: false, WARN: true, ERROR: true, },
        // }
    },
};

const prod = {
    "GTAG_ID": "G-T4N992704N",

    "sentry": {
        dsn: "https://a7f86dbd1a194e6fba2139ec79c7790f@o449271.ingest.sentry.io/5431916",
        environment: "production",
        release: `archipad-cloud@${BUILD_REVISION}`,
    },

    "@archipad/backend/rpc/bootstrap": {
        serverBaseUrl: getServerBaseURL(),
        optimizeCorsPreflight: true,
        contractorPath: "https://archipad.mobi/",
    },
    "@ui-archipad/backend/file-upload": {
        apiKey: "AJ1PcuabPQWP4sfcfckFVz",
        bucket: "archipad-services-fr",
    },
    "@ui-archipad/backend/archiweb/product": {
        apiKey: "pk_live_Le3JdOb6Cmv4DIXDYHzn7e7X",
    },
}
const envConfig = Object.assign({}, config, BUILD_PROD ? prod : stage);

export default envConfig;